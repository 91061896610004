<template>
  <nav
    id="navbarExample"
    class="navbar navbar-expand-lg fixed-top navbar-light"
    aria-label="Main navigation"
  >
    <div class="container">
      <a class="navbar-brand logo-text" href="#">Divine Drapes</a>
      <button
        class="navbar-toggler p-0 border-0"
        type="button"
        id="navbarSideCollapse"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="navbar-collapse offcanvas-collapse"
        id="navbarsExampleDefault"
      >
        <ul class="navbar-nav ms-auto navbar-nav-scroll">
          <li class="nav-item">
            <a
              class="nav-link active"
              aria-current="page"
              href="https://divinedrapes.store/#header"
              >Home</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#services">Services</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#details">Details</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#about-us">About Us</a>
          </li>
        </ul>
        <span class="nav-item">
          <a class="btn-solid-sm" href="#contact">Get quote</a>
        </span>
      </div>
    </div>
  </nav>
</template>

<script>
export default {};
</script>

<style>
</style>


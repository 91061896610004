<template>
  <div class="footer bg-gray">
    <img
      class="decoration-circles"
      src="assets/images/decoration-circles.png"
      alt="alternative"
    />
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h4>
            Connect with Divine Drapes on our social platforms to get a daily
            dose of fashion inspiration, exclusive behind-the-scenes looks, and
            the latest updates on our bespoke tailoring services. Follow us now
            and become part of a community where elegance and style are always
            in vogue!
          </h4>
          <div class="social-container">
            <span class="fa-stack">
              <a href="#your-link">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fab fa-facebook-f fa-stack-1x"></i>
              </a>
            </span>
            <span class="fa-stack">
              <a href="#your-link">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fab fa-twitter fa-stack-1x"></i>
              </a>
            </span>
            <span class="fa-stack">
              <a href="#your-link">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fab fa-pinterest-p fa-stack-1x"></i>
              </a>
            </span>
            <span class="fa-stack">
              <a href="#your-link">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fab fa-instagram fa-stack-1x"></i>
              </a>
            </span>
            <span class="fa-stack">
              <a href="#your-link">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fab fa-youtube fa-stack-1x"></i>
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
<template>
  <!-- Terms and Conditions Section -->
  <div id="terms" class="basic-1">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-container">
            <h2>Terms and Conditions</h2>
            <p class="p-large">
              <b>1. Introduction</b>
              <br />
              Welcome to Divine Drapes. By accessing our website and using our
              services, you agree to be bound by the following terms and
              conditions. If you do not agree to these terms, please do not use
              our website.
            </p>
            <p class="p-large">
              <b>2. Use of Website</b>
              <br />
              This website is intended for personal, non-commercial use. You may
              not use this website for any unlawful purpose. You agree to abide
              by all applicable laws and regulations.
            </p>
            <p class="p-large">
              <b>3. Intellectual Property</b>
              <br />
              All content on this website, including text, graphics, logos, and
              images, is the property of Divine Drapes or its content suppliers
              and is protected by international copyright and intellectual
              property laws.
            </p>
            <p class="p-large">
              <b>4. Product Descriptions</b>
              <br />
              Divine Drapes attempts to be as accurate as possible in its
              product descriptions. However, we do not warrant that product
              descriptions or other content is accurate, complete, reliable,
              current, or error-free.
            </p>

            <p class="p-large">
              <b>5. Limitation of Liability</b>
              <br />
              Divine Drapes will not be liable for any damages arising from the
              use of this website or from any information, content, materials,
              or products included on or otherwise made available to you through
              this website.
            </p>
            <p class="p-large">
              <b>6. Changes to Terms and Conditions</b>
              <br />
              We reserve the right to make changes to our website, policies, and
              these Terms and Conditions at any time.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
</script>
  
  <style scoped>
.text-container {
  text-align: left;
}
</style>
  
<template>
  <NavBar />
  <router-view />

  <FooterBottom />
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
import FooterTop from "@/components/FooterTop.vue";
import FooterBottom from "@/components/FooterBottom.vue";

export default {
  name: "HomeView",
  components: {
    NavBar,
    FooterTop,
    FooterBottom,
  },
};
</script>

<style>
</style>

<template>
  <!-- Terms and Conditions Section -->
  <div id="terms" class="basic-1">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-container">
            <h2>Privacy Policy</h2>
            <p class="p-large">
              <b>1. Introduction</b>
              <br />
              Your privacy is important to us. This privacy policy explains how
              Divine Drapes collects, uses, and protects any information that
              you give us when you use this website.
            </p>
            <p class="p-large">
              <b>2. Information Collection</b>
              <br />
              We may collect the following information: name, contact
              information including email address, demographic information such
              as postcode, preferences, and interests, and other information
              relevant to customer surveys and/or offers.
            </p>
            <p class="p-large">
              <b>3. Use of Information</b>
              <br />
              The information we collect is used to understand your needs and
              provide you with a better service, particularly for internal
              record keeping, improving our products and services, sending
              promotional emails about new products, special offers or other
              information we think you may find interesting.
            </p>
            <p class="p-large">
              <b>4. Security</b>
              <br />
              We are committed to ensuring that your information is secure. In
              order to prevent unauthorized access or disclosure, we have put in
              place suitable physical, electronic, and managerial procedures to
              safeguard and secure the information we collect online.
            </p>

            <p class="p-large">
              <b>5. Cookies</b>
              <br />
              Our website may use cookies to enhance the user experience. You
              can choose to accept or decline cookies.
            </p>
            <p class="p-large">
              <b>6. Controlling Your Personal Information</b>
              <br />
              You may choose to restrict the collection or use of your personal
              information. We will not sell, distribute, or lease your personal
              information to third parties unless we have your permission or are
              required by law.
            </p>
            <p class="p-large">
              <b>7. Changes to Privacy Policy</b>
              <br />
              We reserve the right to modify this privacy policy at any time, so
              please review it frequently.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
</script>
    
    <style scoped>
.text-container {
  text-align: left;
}
</style>
    
<template>
  <div class="copyright bg-gray">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <ul class="list-unstyled li-space-lg p-small">
            <li>
              <a href="https://divinedrapes.store/terms-and-conditions"
                >Terms and Conditions</a
              >
            </li>
            <li>
              <a href="https://divinedrapes.store/privacy-policy"
                >Privacy Policy</a
              >
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12">
          <p class="p-small statement">
            Copyright © 2024 <a href="#">Divine Drapes </a>
          </p>
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12">
          <p class="p-small statement">
            Distributed by
            <a href="https://divinedrapes.store" target="_blank"
              >Divine Drapes
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>

<template>
  <header id="header" class="header">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-xl-5">
          <div class="text-container">
            <h1 class="h1-large">
              Crafting Elegance, Stitching Dreams – Welcome to Divine Drapes!
            </h1>
            <p class="p-large">
              At Divine Drapes, we believe in the power of precision, the charm
              of custom tailoring, and the beauty of bespoke designs. Our
              passion for excellence is woven into every fabric and stitch,
              ensuring that each creation is not just a garment but a personal
              statement. With years of expertise in tailoring and design, we
              transform your visions into wearable art. Dive into a world where
              style meets perfection, and every drape tells a story – your
              story.
            </p>
            <a class="btn-solid-lg" href="#services">Offered services</a>
          </div>
        </div>
        <div class="col-lg-6 col-xl-7">
          <div class="image-container">
            <img
              class="img-fluid"
              style="width: auto !important; height: 800px !important"
              src="assets/images/header-image.png"
              alt="alternative"
            />
          </div>
        </div>
      </div>
    </div>
  </header>
  <div id="services" class="cards-1 bg-gray">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2>Tailored to Perfection – Our Exclusive Services</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-icon">
              <span class="fas fa-headphones-alt"></span>
            </div>
            <div class="card-body">
              <h5 class="card-title">Bespoke Tailoring</h5>
              <p>
                Experience the luxury of custom-tailored garments that fit you
                flawlessly. Our bespoke tailoring service offers personalized
                fittings, high-quality fabrics, and meticulous attention to
                detail, ensuring each piece is a reflection of your unique style
                and preferences.
              </p>
              <a class="read-more no-line" href="#"
                >Learn more <span class="fas fa-long-arrow-alt-right"></span
              ></a>
            </div>
          </div>

          <div class="card">
            <div class="card-icon red">
              <span class="far fa-clipboard"></span>
            </div>
            <div class="card-body">
              <h5 class="card-title">Wedding and Special Occasions</h5>
              <p>
                Make your special day unforgettable with our specialized wedding
                and occasion wear. From elegant bridal gowns to dapper suits, we
                craft attire that complements the significance of your
                celebrations, ensuring you look and feel extraordinary.
              </p>
              <a class="read-more no-line" href="#"
                >Learn more <span class="fas fa-long-arrow-alt-right"></span
              ></a>
            </div>
          </div>

          <div class="card">
            <div class="card-icon green">
              <span class="far fa-comments"></span>
            </div>
            <div class="card-body">
              <h5 class="card-title">Fashion Consultation and Design</h5>
              <p>
                Embark on a creative journey with our fashion consultation and
                design services. Whether you're revamping your wardrobe or
                seeking advice on the latest trends, our expert designers are
                here to guide you with personalized recommendations and
                innovative designs.
              </p>
              <a class="read-more no-line" href="#"
                >Learn more <span class="fas fa-long-arrow-alt-right"></span
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="details" class="basic-1">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-xl-7">
          <div class="image-container">
            <img
              class="img-fluid"
              src="assets/images/details-1.png"
              alt="alternative"
            />
          </div>
        </div>
        <div class="col-lg-6 col-xl-5">
          <div class="text-container">
            <div class="section-title">WHAT WE DO</div>
            <h2>Precision in Every Thread – The Divine Drapes Promise</h2>
            <p>
              At Divine Drapes, our commitment to excellence is evident in every
              aspect of our work. From selecting the finest fabrics to employing
              advanced tailoring techniques, we ensure precision and quality in
              every thread. Our process involves thorough consultations,
              detailed measurements, and a deep understanding of our clients'
              needs, resulting in garments that are not only aesthetically
              pleasing but also comfortable and durable. Trust us to deliver
              elegance and sophistication that transcends the ordinary, making
              every outfit a masterpiece.
            </p>
            <a class="btn-solid-reg" href="#contact">Get quote</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="basic-2" id="about-us">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-xl-5">
          <div class="text-container">
            <div class="section-title">ABOUT US</div>
            <h2>Crafting Elegance for Over 15 Years</h2>
            <p>
              At Divine Drapes, we've been mastering the art of bespoke
              tailoring for over 15 years. Our journey is woven with dedication,
              skill, and a deep understanding of fashion's ever-evolving
              landscape. Rooted in Colombo, Sri Lanka, we blend traditional
              craftsmanship with contemporary design to create garments that are
              as unique as our clients. Our team, rich in experience and
              creativity, is committed to delivering excellence in every stitch.
              From elegant bridal wear to sophisticated professional attire,
              each piece is a testament to our passion for perfection. Join us
              in celebrating a legacy of style and precision at Divine Drapes,
              where every creation is more than just a garment—it's a personal
              statement.
            </p>
            <!-- <a class="btn-outline-reg" href="#">Details</a> -->
          </div>
        </div>
        <div class="col-lg-6 col-xl-7">
          <div class="image-container">
            <img
              class="img-fluid"
              src="assets/images/details-2.png"
              alt="alternative"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div id="features" class="accordion-1">
    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <h2 class="h2-heading">Online service features</h2>
          <p class="p-heading">
            Suspendisse vitae enim arcu. Aliquam convallis risus a felis
            blandit, at mollis nisi bibendum. Aliquam nec purus at ex blandit
            posuere nec a odio. Proin lacinia dolor justo
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-5">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="accordion-icon">
                <span class="fas fa-tv"></span>
              </div>
              <div class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Analyse the product and design plan
                </button>
              </div>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Mauris ornare libero et pharetra hendrerit. Cura elementum
                  lectus quis tellus pretium, vitae lobortis dui sagittis
                  aliquam et enim vel semon anticus
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <div class="accordion-icon blue">
                <span class="fas fa-microphone"></span>
              </div>
              <div class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Find the market opportunities
                </button>
              </div>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Mauris ornare libero et pharetra hendrerit. Cura elementum
                  lectus quis tellus pretium, vitae lobortis dui sagittis
                  aliquam et enim vel semon anticus
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <div class="accordion-icon purple">
                <span class="fas fa-video"></span>
              </div>
              <div class="accordion-header" id="headingThree">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Prepare the product launch campaign
                </button>
              </div>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Mauris ornare libero et pharetra hendrerit. Cura elementum
                  lectus quis tellus pretium, vitae lobortis dui sagittis
                  aliquam et enim vel semon anticus
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <div class="accordion-icon orange">
                <span class="fas fa-tools"></span>
              </div>
              <div class="accordion-header" id="headingFour">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Evaluate the campaign and adjust
                </button>
              </div>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Mauris ornare libero et pharetra hendrerit. Cura elementum
                  lectus quis tellus pretium, vitae lobortis dui sagittis
                  aliquam et enim vel semon anticus
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-7">
          <div class="image-container">
            <img
              class="img-fluid"
              src="assets/images/features-dashboard.png"
              alt="alternative"
            />
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="cards-2 bg-gray">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2 class="h2-heading">Customer testimonials</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <CustomerTestimonial
            :author="'Anuradha Perera'"
            :text="'I needed a traditional Kandyan saree for my wedding, and Divine Drapes exceeded my expectations. The attention to detail and the fit were simply perfect. It was a masterpiece that honored my heritage beautifully.'"
            :gradientStyle="'gradient-floor red-to-blue'"
            :occupation="'School Teacher'"
          />

          <CustomerTestimonial
            :author="'Harith Jayasuriya'"
            :text="'As a lawyer, making a good impression is crucial. The suits I got from Divine Drapes are not just impeccably tailored but also comfortable for long hours in court. They truly understand the essence of professional elegance.'"
            :gradientStyle="'gradient-floor blue-to-purple'"
            :occupation="'Lawyer'"
          />

          <CustomerTestimonial
            :author="'Shalini Mendis'"
            :text="'For my son\'s Vesak festival performance, I wanted something traditional yet comfortable. Divine Drapes crafted a beautiful outfit that captured our culture\'s essence and allowed him the freedom to move and dance.'"
            :gradientStyle="'gradient-floor purple-to-green'"
            :occupation="'Homemaker'"
          />

          <CustomerTestimonial
            :author="'Ranil Perera'"
            :text="'I\'ve been a loyal customer of Divine Drapes for years, and I\'ve never been disappointed. Their attention to detail and commitment to quality are unparalleled. I\'ve recommended them to all my friends and family.'"
            :gradientStyle="'gradient-floor red-to-blue'"
            :occupation="'Businessman'"
          />

          <CustomerTestimonial
            :author="'Samantha Fernando'"
            :text="'I\'ve always struggled to find clothes that fit me well, but Divine Drapes changed that. Their bespoke tailoring service is a dream come true. I\'ve never felt more confident in my clothes.'"
            :gradientStyle="'gradient-floor blue-to-purple'"
            :occupation="'Marketing Executive'"
          />

          <CustomerTestimonial
            :author="'Nimal Bandara'"
            :text="'As a tour guide, I need attire that is both functional and represents Sri Lankan culture. Divine Drapes provided me with outfits that are comfortable for all-day wear and resonate with our rich heritage.'"
            :gradientStyle="'gradient-floor purple-to-green'"
            :occupation="'Tour Guide'"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="basic-3">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-container">
            <h2>Embrace Your Style Today with Divine Drapes!</h2>
            <p class="p-large">
              Discover the fusion of tradition and trend at Divine Drapes.
              Whether it's for a special occasion or everyday elegance, our
              bespoke tailoring and design services are crafted to suit your
              unique style. Don't just dress, make a statement! Book your
              consultation now and step into a world where fabric meets finesse,
              and every stitch tells your story.
            </p>
            <a class="btn-solid-lg" href="#contact">Get free quote</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="contact" class="form-1">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="text-container">
            <div class="section-title">GET QUOTE</div>
            <h2>Get Your Personalized Quote Today</h2>
            <p>
              Embark on your journey to impeccable style with Divine Drapes. Our
              tailored approach ensures that every piece we create is a
              reflection of your unique taste and preferences. Start with a
              simple quote request, and let us guide you towards your dream
              attire.
            </p>
            <!-- <ul class="list-unstyled li-space-lg">
              <li class="d-flex">
                <i class="fas fa-square"></i>
                <div class="flex-grow-1" style="text-align: left">
                  Step 1: Share Your Vision
                </div>
              </li>
              <li class="d-flex">
                <i class="fas fa-square"></i>
                <div class="flex-grow-1" style="text-align: left">
                  Step 2: Consultation & Design
                </div>
              </li>
              <li class="d-flex">
                <i class="fas fa-square"></i>
                <div class="flex-grow-1" style="text-align: left">
                  Step 3: Tailored Quote & Timeline
                </div>
              </li>
            </ul> -->
          </div>
        </div>
        <div class="col-lg-6">
          <form>
            <div class="form-group">
              <input
                type="text"
                class="form-control-input"
                placeholder="Name"
                required
              />
            </div>
            <div class="form-group">
              <input
                type="email"
                class="form-control-input"
                placeholder="Email"
                required
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control-input"
                placeholder="Industry"
                required
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control-input"
                placeholder="Your product"
                required
              />
            </div>
            <div class="form-group">
              <button type="submit" class="form-control-submit-button">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <FooterTop />
  <button id="myBtn">
    <img src="assets/images/up-arrow.png" alt="alternative" />
  </button>
</template>
 
 
<script>
import CustomerTestimonial from "@/components/CustomerTestimonial.vue";
import FooterTop from "@/components/FooterTop.vue";
export default {
  components: {
    CustomerTestimonial,
    FooterTop,
  },
  setup() {
    return {
      CustomerTestimonial,
      FooterTop,
    };
  },
};
</script>
 
 <style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
 
<template>
  <div class="card">
    <img class="quotes" src="assets/images/quotes.svg" alt="alternative" />
    <div class="card-body">
      <p class="testimonial-text">
        {{ text }}
      </p>
      <div class="testimonial-author">{{ author }}</div>
      <div class="occupation">{{ occupation }}</div>
    </div>
    <div :class="gradientStyle"></div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    author: {
      type: String,
      required: true,
    },
    occupation: {
      type: String,
      required: true,
    },
    gradientStyle: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
</style>